<template>
  <div
    v-if="club"
    class="rating-club-widget__header"
  >
    {{ club.name }}
    <a-button
      @click="handleClubLink"
    >
      ПЕРЕЙТИ В КЛУБ
    </a-button>
    <a-typography-text>
      До розыгрыша командной футболки {{ club.name }} твоему клубу
      осталось пригласить {{ usersCountText }}
      {{ wordDeclensionHelper.getFriendDeclension(usersCountText) }}
    </a-typography-text>
  </div>
  <div
    v-else
    class="rating-club-widget__header"
  >
    <a-typography-text>
      Вступи в рейтинговый клуб:
    </a-typography-text>
    <LinkTemplate
      text-before="см. "
      link-text="Важный раздел"
      text-after=", раздел:"
      @link-click="handleImportantSectionLinkClick"
    />
    <a-typography-text>
      раздел Как выбрать рейтинговый клуб
    </a-typography-text>
  </div>
</template>

<script
  lang="ts"
>

import {
  defineComponent,
  PropType,
  inject,
  computed,
} from 'vue';
import {
  Button,
  TypographyText,
} from 'ant-design-vue';
import UserInfoResponse from '@/shared/Api/Model/UserInfoApi/UserInfoResponse';
import ServiceContainerInterface
  from '@/app/Services/ServiceContainer/Contract/ServiceContainerInterface';
import RatingClubHelper from '@/shared/Helpers/RatingClubHelper';
import WordDeclensionHelper from '@/shared/Helpers/WordDeclensionHelper';
import LinkTemplate from '@/shared/Ui/LinkTemplate.vue';
import ClubModel from '@/shared/Api/Model/ClubModel';

export default defineComponent({
  components: {
    AButton: Button,
    ATypographyText: TypographyText,
    LinkTemplate,
  },
  props: {
    user: {
      type: Object as PropType<UserInfoResponse | null>,
      required: true,
    },
  },
  setup(props) {
    const serviceContainer = inject<ServiceContainerInterface>('serviceContainer');

    if (!serviceContainer) {
      throw new Error('serviceContainer not injected');
    }

    const club = computed<ClubModel | null>(() => props.user?.clubs[0] ?? null);

    const usersCountText = computed(() => {
      if (!props.user) {
        return 0;
      }

      return RatingClubHelper.getRemainingUsersForClub(props.user);
    });

    async function handleClubLink(): Promise<void> {
      if (!serviceContainer) {
        return;
      }

      if (!club.value) {
        return;
      }

      window.open(club.value.link);
    }

    const wordDeclensionHelper = WordDeclensionHelper;

    async function handleImportantSectionLinkClick() {
      if (!serviceContainer) {
        return;
      }

      const importantSectionLink = await serviceContainer
        .externalLinkService
        .getTelegramImportantSection();

      window.open(importantSectionLink);
    }

    return {
      club,
      usersCountText,
      wordDeclensionHelper,
      handleImportantSectionLinkClick,
      handleClubLink,
    };
  },
});
</script>

<style
  scoped
  lang="scss"
>
.rating-club-widget {
  &__header {
    display: flex;
    width: 100%;
    gap: 10px;
    flex-direction: column;
  }
}
</style>
