import UserInfoResponse from '@/shared/Api/Model/UserInfoApi/UserInfoResponse';
import RingNameEnum from '@/entities/Ring/Enum/RingNameEnum';
import RingLimitModel from '@/entities/Ring/Model/RingLimitModel';
import RingTypeEnum from '@/entities/Ring/Enum/RingTypeEnum';
import moment from 'moment';

class RingHelper {
  static isBlack(user: UserInfoResponse) {
    return user.ring === RingNameEnum.BLACK;
  }

  static getRingLimit(user: UserInfoResponse): RingLimitModel {
    const map: { [key in RingTypeEnum]: RingLimitModel } = {
      white: {
        you: 25000,
        challenges: 11,
        tournaments: 4,
        challengesWithTournaments: 4,
        monthInYouFC: 4,
      },
      blue: {
        you: 35000,
        challenges: 15,
        tournaments: 6,
        challengesWithTournaments: 6,
        monthInYouFC: 7,
      },
      purple: {
        you: 50000,
        challenges: 21,
        tournaments: 8,
        challengesWithTournaments: 8,
        monthInYouFC: 9,
      },
      brown: {
        you: 75000,
        challenges: 30,
        tournaments: 12,
        challengesWithTournaments: 12,
        monthInYouFC: 12,
      },
      black: {
        you: 0,
        challenges: 0,
        tournaments: 0,
        challengesWithTournaments: 0,
        monthInYouFC: 0,
      },
    };

    const key = this.getRingKey(user);

    return map[key];
  }

  static getRingKey(user: UserInfoResponse): RingTypeEnum {
    if (!user.ring) {
      throw new Error('Ring not exists.');
    }

    const map = {
      [RingNameEnum.WHITE]: RingTypeEnum.WHITE,
      [RingNameEnum.BLUE]: RingTypeEnum.BLUE,
      [RingNameEnum.PURPLE]: RingTypeEnum.PURPLE,
      [RingNameEnum.BROWN]: RingTypeEnum.BROWN,
      [RingNameEnum.BLACK]: RingTypeEnum.BLACK,
    };

    return map[user.ring];
  }

  static getRemainingTournaments(user: UserInfoResponse): number {
    if (!user.ring || user.ring === RingNameEnum.BLACK) {
      return 0;
    }

    const conditions = this.getRingLimit(user);

    const tournaments = conditions.tournaments - (user.tournaments.length ?? 0);

    return tournaments < 0 ? 0 : tournaments;
  }

  static getRemainingChallenges(user: UserInfoResponse): number {
    if (!user.ring || user.ring === RingNameEnum.BLACK) {
      return 0;
    }

    const tournaments = this.getRemainingTournaments(user);

    const conditions = this.getRingLimit(user);

    const challengesCondition = tournaments === 0
      ? conditions.challengesWithTournaments
      : conditions.challenges;

    const succeedChallenges = (user.challenges ?? [])
      .filter((challenge) => challenge.success);

    const result = challengesCondition - succeedChallenges.length;

    return result < 0 ? 0 : result;
  }

  static getRemainingYou(user: UserInfoResponse): number {
    if (!user.ring || user.ring === RingNameEnum.BLACK) {
      return 0;
    }

    const conditions = this.getRingLimit(user);

    const you = conditions.you - Number(user.rating ?? 0);

    return you < 0 ? 0 : you;
  }

  static getRemainingDays(user: UserInfoResponse): number {
    if (!user.ring || user.ring === RingNameEnum.BLACK) {
      return 0;
    }

    const conditions = this.getRingLimit(user);

    let days = 0;

    if (user.date) {
      days = Math.floor(
        moment.duration(
          moment().diff(moment(user.date, 'DD.MM.YYYY')),
        ).asDays(),
      );
    }

    days = conditions.monthInYouFC * 30 - days;

    return days < 0 ? 0 : days;
  }
}

export default RingHelper;
