import AxiosClientServiceInterface
  from '@/app/Services/AxiosClientService/Contract/AxiosClientServiceInterface';
import ShockModeApiInterface from '@/shared/Api/Contract/Api/ShockModeApiInterface';

class ShockModeApi implements ShockModeApiInterface {
  private clientService: AxiosClientServiceInterface;

  constructor(
    clientService: AxiosClientServiceInterface,
  ) {
    this.clientService = clientService;
  }

  async createImage(): Promise<Blob> {
    return this.clientService.instance.post('/v2/shock-mode/createImage', {}, {
      headers: {
        Accept: 'image/jpeg',
      },
      responseType: 'arraybuffer',
    })
      .then((response) => new Blob([response.data]));
  }
}

export default ShockModeApi;
