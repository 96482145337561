import { Module } from 'vuex';
import TelegramBotStateModel from '@/app/Store/Model/TelegramBotStateModel';
import ServiceContainerInterface
  from '@/app/Services/ServiceContainer/Contract/ServiceContainerInterface';

export function getTelegramBotStore(
  serviceContainer: ServiceContainerInterface,
): Module<TelegramBotStateModel, unknown> {
  return {
    state: {
      authBot: undefined,
    },
    getters: {
      getAuthBot(state) {
        return state.authBot;
      },
    },
    mutations: {
      setAuthBot(state, payload) {
        state.authBot = payload;
      },
    },
    actions: {
      async updateAuthBot({ commit }) {
        const botInfo = await serviceContainer.apiService.telegramBot
          .getAuthBot()
          .catch(() => null);

        commit('setAuthBot', botInfo);
      },
    },
  };
}
