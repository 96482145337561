<template>
  <div
    class="user-summary-widget"
  >
    <a-typography-text
      :strong="true"
    >
      {{ userSummary.ring }}
    </a-typography-text>
    <a-typography-text
      :strong="true"
    >
      Баланс YOU
    </a-typography-text>
    <a-typography-text>
      Рейтинг: {{ userSummary.rating }}
    </a-typography-text>
    <a-typography-text>
      Доступно: {{ userSummary.credit }}
    </a-typography-text>
    <a-typography-text
      :strong="true"
    >
      Золотые жетоны
    </a-typography-text>
    <a-typography-text>
      Доступно: {{ userSummary.goldCoins }}
    </a-typography-text>
  </div>
</template>

<script
  lang="ts"
>
import {
  TypographyText,
} from 'ant-design-vue';
import {
  computed,
  defineComponent,
  inject,
  PropType,
} from 'vue';
import ServiceContainerInterface
  from '@/app/Services/ServiceContainer/Contract/ServiceContainerInterface';
import UserInfoResponse from '@/shared/Api/Model/UserInfoApi/UserInfoResponse';

export default defineComponent({
  components: {
    ATypographyText: TypographyText,
  },
  props: {
    user: {
      type: Object as PropType<UserInfoResponse | null>,
      required: true,
    },
  },
  setup(props) {
    const serviceContainer = inject<ServiceContainerInterface>('serviceContainer');

    if (!serviceContainer) {
      throw new Error('serviceContainer not injected');
    }

    const userSummary = computed(() => {
      const user = serviceContainer.store.getters.getUser;

      return {
        ring: props.user?.ring ? `${user.ring} перстень` : 'Перстень - нет',
        rating: props.user?.rating ?? 0,
        credit: props.user?.credit ?? 0,
        goldCoins: props.user?.goldCoins ?? 0,
      };
    });

    return {
      userSummary,
    };
  },
});
</script>

<style
  scoped
  lang="scss"
>
.user-summary-widget {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
</style>
