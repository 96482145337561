import AuthorizationApiInterface from '@/shared/Api/Contract/Api/AuthorizationApiInterface';
import AuthorizationApi from '@/shared/Api/Api/AuthorizationApi';
import ApiServiceInterface from '@/shared/Api/Contract/ApiServiceInterface';
import AxiosClientServiceInterface
  from '@/app/Services/AxiosClientService/Contract/AxiosClientServiceInterface';
import UtilsServiceInterface from '@/app/Services/UtilsService/Contract/UtilsServiceInterface';
import UserInfoApi from '@/shared/Api/Api/UserInfoApi';
import UserInfoApiInterface from '@/shared/Api/Contract/Api/UserInfoApiInterface';
import ChallengesApiInterface from '@/shared/Api/Contract/Api/ChallengesApiInterface';
import ChallengesApi from '@/shared/Api/Api/ChallengesApi';
import TelegramBotApiInterface from '@/shared/Api/Contract/Api/TelegramBotApiInterface';
import TelegramBotApi from '@/shared/Api/Api/TelegramBotApi';
import InvitationChallengeApiInterface
  from '@/shared/Api/Contract/Api/InvitationChallengeApiInterface';
import InvitationChallengeApi from '@/shared/Api/Api/InvitationChallengeApi';
import ReportTypeApiInterface from '@/shared/Api/Contract/Api/ReportTypeApiInterface';
import ReportTypeApi from '@/shared/Api/Api/ReportTypeApi';
import LinksApiInterface from '@/shared/Api/Contract/Api/LinksApiInterface';
import LinksApi from '@/shared/Api/Api/LinksApi';
import ShockModeApiInterface from '@/shared/Api/Contract/Api/ShockModeApiInterface';
import ShockModeApi from '@/shared/Api/Api/ShockModeApi';
import RPCApiInterface from '@/shared/Api/Contract/Api/RPC/RPCApiInterface';
import RPCApi from '@/shared/Api/Api/RPC/RPCApi';

class ApiService implements ApiServiceInterface {
  private readonly client: AxiosClientServiceInterface;

  private _authorizationApi?: AuthorizationApiInterface;

  private _userInfoApi?: UserInfoApiInterface;

  private _challengesApi?: ChallengesApiInterface;

  private _shockModeApi?: ShockModeApiInterface;

  private _telegramBotInfoApi?: TelegramBotApiInterface;

  private _invitationChallengeApi?: InvitationChallengeApiInterface;

  private _reportTypeApi?: ReportTypeApiInterface;

  private _linksApi?: LinksApiInterface;

  private _rpc?: RPCApiInterface;

  private readonly utilsService: UtilsServiceInterface;

  constructor(
    client: AxiosClientServiceInterface,
    utilsService: UtilsServiceInterface,
  ) {
    this.utilsService = utilsService;
    this.client = client;
  }

  get authorizationApi(): AuthorizationApiInterface {
    if (!this._authorizationApi) {
      this._authorizationApi = new AuthorizationApi(
        this.client,
        this.utilsService,
      );
    }

    return this._authorizationApi;
  }

  get userInfoApi(): UserInfoApiInterface {
    if (!this._userInfoApi) {
      this._userInfoApi = new UserInfoApi(
        this.client,
      );
    }

    return this._userInfoApi;
  }

  get challengesApi(): ChallengesApiInterface {
    if (!this._challengesApi) {
      this._challengesApi = new ChallengesApi(
        this.client,
      );
    }

    return this._challengesApi;
  }

  get shockModeApi(): ShockModeApiInterface {
    if (!this._shockModeApi) {
      this._shockModeApi = new ShockModeApi(
        this.client,
      );
    }

    return this._shockModeApi;
  }

  get telegramBot(): TelegramBotApiInterface {
    if (!this._telegramBotInfoApi) {
      this._telegramBotInfoApi = new TelegramBotApi(
        this.client,
      );
    }

    return this._telegramBotInfoApi;
  }

  get invitationChallengeApi(): InvitationChallengeApiInterface {
    if (!this._invitationChallengeApi) {
      this._invitationChallengeApi = new InvitationChallengeApi(
        this.client,
      );
    }

    return this._invitationChallengeApi;
  }

  get reportTypeApi(): ReportTypeApiInterface {
    if (!this._reportTypeApi) {
      this._reportTypeApi = new ReportTypeApi(
        this.client,
      );
    }

    return this._reportTypeApi;
  }

  get linksApi(): LinksApiInterface {
    if (!this._linksApi) {
      this._linksApi = new LinksApi(
        this.client,
      );
    }

    return this._linksApi;
  }

  get rpc(): RPCApiInterface {
    if (!this._rpc) {
      this._rpc = new RPCApi(
        this.client,
      );
    }

    return this._rpc;
  }
}

export default ApiService;
