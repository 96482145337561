<template>
  <div
    class="treasure-island__block"
  >
    <a-typography-text
      :strong="true"
    >
      Как получить золотые жетоны?
    </a-typography-text>
    <a-typography-text>
      1. Зайди в раздел "Позвать друга",
    </a-typography-text>
    <a-typography-text>
      2. пригласи его по ссылке из этого раздела,
    </a-typography-text>
    <a-typography-text>
      3. затем нажми кнопку "Бросить вызов",
      введи хештег цели-челленджа своего друга и хештег своей цели-челленджа,
    </a-typography-text>
    <a-typography-text>
      4. выбери пункт "Соперник впервые в YouFC",
    </a-typography-text>
    <a-typography-text>
      5. как только Друг выполнит свою цель-челлендж и получит первые 600 YOU,
      ты увидишь 20 золотых жетонов на своем счёте здесь.
    </a-typography-text>
    <a-button
      @click="handleTreasureIslandClick"
    >
      Смотреть сокровища YouFC
    </a-button>
  </div>
</template>

<script
  lang="ts"
>

import {
  defineComponent,
  inject,
} from 'vue';
import {
  Button,
  TypographyText,
} from 'ant-design-vue';
import ServiceContainerInterface
  from '@/app/Services/ServiceContainer/Contract/ServiceContainerInterface';

export default defineComponent({
  components: {
    AButton: Button,
    ATypographyText: TypographyText,
  },
  setup() {
    const serviceContainer = inject<ServiceContainerInterface>('serviceContainer');

    if (!serviceContainer) {
      throw new Error('serviceContainer not injected');
    }

    async function handleTreasureIslandClick() {
      if (!serviceContainer) {
        return;
      }

      const treasureIslandLink = await serviceContainer.externalLinkService.getTreasureIsland();

      window.open(treasureIslandLink);
    }

    return {
      handleTreasureIslandClick,
    };
  },
});
</script>

<style
  scoped
  lang="scss"
>
.treasure-island {
  &__block {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }
}
</style>
