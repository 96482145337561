<template>
  <div
    v-if="showToolbar"
    class="row"
  >
    <div>
      <img
        v-if="showImage"
        v-click-outside="handleClickOutside"
        :src="state.imageUrl"
        alt="Preview"
        class="preview__image"
      />
    </div>
    <div
      class="toolbar"
    >
      <div
        class="toolbar__left"
      >
        <a-typography-text
          :strong="true"
        >
          {{ $route.meta.title }}
        </a-typography-text>
      </div>
      <div
        class="toolbar__right"
      >
        <div
          v-if="user"
          class="counter"
          @click="state.showImage = !state.showImage"
        >
          {{ emojiHelper.getFire() }} {{ user.shockMode.counter }}
        </div>
        <div
          v-if="user"
          class="user-info"
        >
          <img
            v-if="user.photo"
            :src="user.photo"
            class="user-info__photo"
            alt=""
          >
          <span
            v-else
            class="user-info__name"
          >
            {{ userName }}
          </span>
        </div>
      </div>
    </div>
  </div>
  <div
    class="row"
  >
    <div
      class="content"
    >
      <slot/>
    </div>
  </div>
</template>

<script
  lang="ts"
>
import {
  computed,
  defineComponent,
  inject,
  onMounted,
  PropType,
  reactive,
  watch,
} from 'vue';
import UserInfoResponse from '@/shared/Api/Model/UserInfoApi/UserInfoResponse';
import { useRouter } from 'vue-router';
import {
  TypographyText,
} from 'ant-design-vue';
import ServiceContainerInterface
  from '@/app/Services/ServiceContainer/Contract/ServiceContainerInterface';
import EmojiHelper from '@/shared/Helpers/EmojiHelper';

export default defineComponent({
  name: 'LayoutComponent',
  props: {
    user: {
      type: Object as PropType<UserInfoResponse | null>,
      default: () => null,
    },
  },
  components: {
    ATypographyText: TypographyText,
  },
  setup(props) {
    const state = reactive<{
      showImage: boolean;
      imageUrl: string;
    }>({
      showImage: false,
      imageUrl: '',
    });
    const serviceContainer = inject<ServiceContainerInterface>('serviceContainer');

    if (!serviceContainer) {
      throw new Error('serviceContainer not injected');
    }

    const router = useRouter();

    const userName = computed(() => {
      if (!props.user) {
        return '';
      }

      if (props.user.fullName) {
        return props.user.fullName;
      }

      if (props.user.username) {
        return props.user.username;
      }

      return '';
    });

    const showToolbar = computed(
      () => [
        'userAccount',
      ].includes(router.currentRoute.value.name as string),
    );

    const showImage = computed(() => {
      if (router.currentRoute.value.name !== 'userAccount') {
        return false;
      }

      return state.imageUrl.length > 0 && state.showImage;
    });

    async function loadImage() {
      if (!serviceContainer) {
        throw new Error('serviceContainer not injected');
      }

      const blob = await serviceContainer.apiService.shockModeApi.createImage();

      const urlCreator = window.URL || window.webkitURL;
      state.imageUrl = urlCreator.createObjectURL(blob);
    }

    onMounted(async () => {
      if (router.currentRoute.value.name !== 'userAccount') {
        return;
      }

      await loadImage();
    });

    watch(() => router.currentRoute.value.name, async () => {
      if (router.currentRoute.value.name !== 'userAccount') {
        return;
      }

      await loadImage();
    });

    function handleClickOutside() {
      if (state.showImage) {
        state.showImage = false;
      }
    }

    return {
      userName,
      showToolbar,
      state,
      showImage,
      emojiHelper: EmojiHelper,
      handleClickOutside,
    };
  },
});
</script>

<style
  scoped
  lang="scss"
>
.row {
  width: 350px;
  margin: 0 auto;
}

.content {
  width: 100%;
  padding: 10px 20px 0;
}

.toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #3f3f3f;
  padding: 10px 20px;

  &__title {
    margin: 0;
  }

  &__right {
    display: flex;
  }
}

.user-info {
  display: flex;
  align-items: center;
  gap: 20px;

  &__photo {
    width: 50px;
    height: 50px;
  }
}

.counter {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
}

.preview {
  &__image {
    position: fixed;
    width: 350px;
    top: 70px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 100;
  }
}
</style>
