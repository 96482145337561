import AuthorizationApiInterface from '@/shared/Api/Contract/Api/AuthorizationApiInterface';
import AuthResponseModel from '@/shared/Api/Model/AuthorizationApi/AuthResponseModel';
import AxiosClientServiceInterface from '@/app/Services/AxiosClientService/Contract/AxiosClientServiceInterface';
import UtilsServiceInterface
  from '../../../app/Services/UtilsService/Contract/UtilsServiceInterface';

class AuthorizationApi implements AuthorizationApiInterface {
  private clientService: AxiosClientServiceInterface;

  private readonly utilsService: UtilsServiceInterface;

  constructor(
    clientService: AxiosClientServiceInterface,
    utilsService: UtilsServiceInterface,
  ) {
    this.clientService = clientService;
    this.utilsService = utilsService;
  }

  auth = async (code: string): Promise<AuthResponseModel> => {
    const timeZoneOffset = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const data = {
      code,
      timeZoneOffset,
    };

    return this.clientService.instance
      .post<AuthResponseModel>('/auth/telegram/code', data)
      .then((response) => response.data);
  };

  authByCode = async (code: string): Promise<AuthResponseModel> => {
    const timeZoneOffset = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const data = {
      code,
      timeZoneOffset,
    };

    return this.clientService.instance
      .post<AuthResponseModel>(`/auth/telegram/code/${code}`, data)
      .then((response) => response.data);
  };

  refresh = async (refreshToken: string): Promise<AuthResponseModel> => this.clientService.instance
    .post<AuthResponseModel>('/token/refresh', { refresh_token: refreshToken })
    .then((response) => this.utilsService.object
      .mapObjectSnakeKeysToCamelCaseRecursive(response.data) as AuthResponseModel);
}

export default AuthorizationApi;
