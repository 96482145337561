import AxiosClientServiceInterface
  from '@/app/Services/AxiosClientService/Contract/AxiosClientServiceInterface';
import LinksResponse from '@/shared/Api/Model/Links/LinksResponse';
import LinksApiInterface from '@/shared/Api/Contract/Api/LinksApiInterface';

class LinksApi implements LinksApiInterface {
  private clientService: AxiosClientServiceInterface;

  constructor(
    clientService: AxiosClientServiceInterface,
  ) {
    this.clientService = clientService;
  }

  async list(): Promise<LinksResponse> {
    return this.clientService.instance.get<LinksResponse>('/custom/links')
      .then((response) => response.data);
  }
}

export default LinksApi;
