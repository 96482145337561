<template>
  <div
    class="friends-widget__header"
  >
    <template
      v-if="hasLink"
    >
      <a-typography-text
        copyable
      >
        {{ user.link }}
      </a-typography-text>
    </template>
    <template
      v-else
    >
      <LinkTemplate
        text-before="Получи ссылку в "
        link-text="Важном разделе"
        text-after=","
      />
      <a-typography-text>
        в разделе Взять реферальную ссылку
      </a-typography-text>
    </template>
    <a-button
      @click.stop="handleChallengeClick"
    >
      Бросить вызов
    </a-button>
  </div>
</template>

<script
  lang="ts"
>

import {
  computed,
  defineComponent,
  inject,
  PropType,
} from 'vue';
import {
  Button,
  TypographyText,
} from 'ant-design-vue';
import LinkTemplate from '@/shared/Ui/LinkTemplate.vue';
import ServiceContainerInterface
  from '@/app/Services/ServiceContainer/Contract/ServiceContainerInterface';
import UserInfoResponse from '@/shared/Api/Model/UserInfoApi/UserInfoResponse';

export default defineComponent({
  components: {
    LinkTemplate,
    AButton: Button,
    ATypographyText: TypographyText,
  },
  emits: [
    'challenge',
  ],
  props: {
    user: {
      type: Object as PropType<UserInfoResponse | null>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const serviceContainer = inject<ServiceContainerInterface>('serviceContainer');

    if (!serviceContainer) {
      throw new Error('serviceContainer not injected');
    }

    const hasLink = computed(() => !!props.user?.link);

    function handleChallengeClick() {
      emit('challenge');
    }

    return {
      hasLink,
      handleChallengeClick,
    };
  },
});
</script>

<style
  scoped
  lang="scss"
>
.friends-widget {
  &__header {
    display: flex;
    width: 100%;
    gap: 10px;
    flex-direction: column;
  }
}
</style>
