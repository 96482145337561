class ElementHelper {
  static isCursorInsideElement(element: HTMLElement, event: MouseEvent): boolean {
    const rect = element.getBoundingClientRect();

    if (event.x < rect.left || event.x > rect.right) {
      return false;
    }

    return !(event.y < rect.top || event.y > rect.bottom);
  }
}

export default ElementHelper;
