import UserInfoResponse from '@/shared/Api/Model/UserInfoApi/UserInfoResponse';
import InvitedUsersEnum from '@/entities/InvitedUsersEnum';

class RatingClubHelper {
  static getRemainingUsersForClub(user: UserInfoResponse) {
    const usersCount = user.invitedClubMembersCount ?? 0;

    const cofficient = usersCount % InvitedUsersEnum.TARGET_INVITED_USERS;

    return InvitedUsersEnum.TARGET_INVITED_USERS - cofficient;
  }
}

export default RatingClubHelper;
