<template>
  <div>

    <a-modal
      v-model:visible="state.showModal"
      :centered="true"
      :closable="false"
      title="Улыбка!"
    >
      <p>
        Вы уже зарегистрированы!
      </p>

      <template
        #footer
      >
        <a-button
          key="submit"
          type="primary"
          @click="handleModalClose"
        >
          Закрыть
        </a-button>
      </template>
    </a-modal>
    <div
      class="tournament-widget__buttons"
    >
      <template
        v-if="state.tournamentList.length > 0"
      >
        <a-button
          v-for="tournamentTemplate in state.tournamentList"
          :key="tournamentTemplate.id"
          @click="handleTournamentClick(tournamentTemplate)"
        >
          {{ tournamentTemplate.name }}
        </a-button>
      </template>
      <a-typography-text
        v-else
      >
        Скоро здесь откроется регистрация на турнир
      </a-typography-text>
    </div>
  </div>
</template>

<script
  lang="ts"
>
import {
  defineComponent,
  inject,
  onMounted,
  onUnmounted,
  reactive,
} from 'vue';
import ServiceContainerInterface
  from '@/app/Services/ServiceContainer/Contract/ServiceContainerInterface';
import TournamentTemplateModel from '@/shared/Api/Model/RPC/Tournament/TournamentTemplateModel';
import {
  useRouter,
} from 'vue-router';

interface State {
  tournamentList: TournamentTemplateModel[];
  showModal: boolean;
}

export default defineComponent({
  name: 'TournamentWidget',

  setup() {
    const state: State = reactive<State>({
      tournamentList: [],
      showModal: false,
    });

    const serviceContainer = inject<ServiceContainerInterface>('serviceContainer');

    const router = useRouter();

    if (!serviceContainer) {
      throw new Error('serviceContainer not injected');
    }

    async function updateTournamentList() {
      if (!serviceContainer) {
        throw new Error('serviceContainer not injected');
      }

      state.tournamentList = await serviceContainer.apiService.rpc.tournament.listActual.call();
    }

    function handleTournamentClick(tournamentTemplate: TournamentTemplateModel): void {
      if (!serviceContainer) {
        throw new Error('serviceContainer not injected');
      }

      if ((tournamentTemplate.challenges ?? []).length === 0) {
        serviceContainer.store.dispatch('addTournamentTemplate', tournamentTemplate);

        router.push({ name: 'tournament-challenge' });

        return;
      }

      state.showModal = true;
    }

    let intervalId: number;

    onMounted(async () => {
      await updateTournamentList();

      intervalId = setInterval(updateTournamentList, 5000);
    });

    onUnmounted(() => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    });

    function handleModalClose() {
      state.showModal = false;
    }

    return {
      state,
      handleTournamentClick,
      handleModalClose,
    };
  },
});
</script>

<style
  scoped
  lang="scss"
>
.tournament-widget {
  &__buttons {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}
</style>
