import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "home-page" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_typography_title = _resolveComponent("a-typography-title")!
  const _component_a_typography_paragraph = _resolveComponent("a-typography-paragraph")!
  const _component_TelegramAuthWidget = _resolveComponent("TelegramAuthWidget")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_typography_title, {
      level: 3,
      class: "home-page__title"
    }, {
      default: _withCtx(() => [
        _createTextVNode(" YouFC — полезная соцсеть для полезных действий ")
      ]),
      _: 1
    }),
    _createVNode(_component_a_typography_paragraph, null, {
      default: _withCtx(() => [
        _createTextVNode(" Заходи и качество твоей жизни повысится! ")
      ]),
      _: 1
    }),
    (!_ctx.user && _ctx.authBot)
      ? (_openBlock(), _createBlock(_component_TelegramAuthWidget, {
          key: 0,
          mode: "redirect",
          "bot-name": _ctx.authBot.name,
          "redirect-url": _ctx.authBot.redirectUri
        }, null, 8, ["bot-name", "redirect-url"]))
      : _createCommentVNode("", true)
  ]))
}