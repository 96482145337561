import { createStore } from 'vuex';
import ServiceContainerInterface
  from '@/app/Services/ServiceContainer/Contract/ServiceContainerInterface';
import { authStore } from '@/app/Store/authStore';
import { getUserModule } from '@/app/Store/userStore';
import { getChallengeModule } from '@/app/Store/challengesStore';
import { getTelegramBotStore } from '@/app/Store/telegramBotStore';
import { tournamentTemplate } from '@/app/Store/tournamentTemplateStore';
import { invitationCode } from '@/app/Store/invitationCodeStore';

export const store = (serviceContainer: ServiceContainerInterface) => {
  const store = createStore({});

  const userModule = getUserModule(serviceContainer);
  store.registerModule('userModule', userModule);

  const challengeModule = getChallengeModule(serviceContainer);
  store.registerModule('challengeModule', challengeModule);

  const telegramBotModule = getTelegramBotStore(serviceContainer);
  store.registerModule('telegramBotModule', telegramBotModule);

  store.registerModule('authModule', authStore);

  store.registerModule('tournamentTemplate', tournamentTemplate);

  store.registerModule('invitationCode', invitationCode);

  return store;
};
