import { Module } from 'vuex';
import ServiceContainerInterface
  from '@/app/Services/ServiceContainer/Contract/ServiceContainerInterface';
import ChallengeStateModel from '@/app/Store/Model/ChallengeStateModel';

export function getChallengeModule(serviceContainer: ServiceContainerInterface): Module<ChallengeStateModel, unknown> {
  return {
    state: {
      challengeList: [],
    },
    getters: {
      getChallengeList(state) {
        return state.challengeList;
      },
    },
    mutations: {
      setChallengeList(state, payload) {
        state.challengeList = payload;
      },
    },
    actions: {
      async updateChallengeList({ commit, getters, dispatch }) {
        let user = getters.getUser;
        if (!user) {
          await dispatch('updateUser');

          if (!getters.getUser) {
            return;
          }

          user = getters.getUser;
        }

        const challengeList = await serviceContainer.apiService.rpc.challenge.listActual.call();

        commit('setChallengeList', challengeList);
      },
    },
  };
}
