import ApplyMethodInterface
  from '@/shared/Api/Contract/Api/RPC/InvitationCode/ApplyMethodInterface';
import AxiosClientServiceInterface
  from '@/app/Services/AxiosClientService/Contract/AxiosClientServiceInterface';
import InvitationCodeModel from '@/shared/Api/Model/RPC/InvitationCode/InvitationCodeModel';

class ApplyMethod implements ApplyMethodInterface {
  private readonly client: AxiosClientServiceInterface;

  constructor(
    client: AxiosClientServiceInterface,
  ) {
    this.client = client;
  }

  async call(code: string): Promise<InvitationCodeModel> {
    return this.client.instance.post<InvitationCodeModel>('/v2/invitation-code/apply', { code })
      .then((response) => response.data);
  }
}

export default ApplyMethod;
