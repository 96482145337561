import ChallengeResponseModel from '@/shared/Api/Model/Challenges/ChallengeResponseModel';
import moment from 'moment';
import ChallengePeriodEnum from '@/entities/ChallengePeriodEnum';

class ChallengeHelper {
  static changeChallengeHash(id: string): string {
    if (id.startsWith('#w')) {
      return id;
    }

    return id.replace('#', '#f');
  }

  static getDaysForFinish(challenge: ChallengeResponseModel): number {
    const endDate = this.getChallengeEndDate(challenge);

    const currentDayStart = moment().startOf('day');
    const diff = endDate.diff(currentDayStart);

    const days = Math.floor(moment.duration(diff).asDays());

    return days < 0 ? 0 : days;
  }

  static isChallengeActive(challenge: ChallengeResponseModel): boolean {
    // TODO: убрать на бэк
    if (challenge.success || !challenge.approved || challenge.deleted) {
      return false;
    }

    const currentDate = moment();
    const subtractDate = (challenge.period ?? 0) + ChallengePeriodEnum.AWARDS_PERIOD;
    const endDate = moment(this.getStartDate(challenge)).subtract(-subtractDate, 'd');

    return endDate.diff(currentDate) > 0;
  }

  static getChallengeStartDate(challenge: ChallengeResponseModel): string {
    return moment(this.getStartDate(challenge)).format('DD.MM.YYYY');
  }

  static getChallengeEndDate(challenge: ChallengeResponseModel): moment.Moment {
    return moment(this.getStartDate(challenge)).subtract(-(challenge.period ?? 0), 'd');
  }

  static getAwardsStartDate(challenge: ChallengeResponseModel): string {
    return moment(this.getStartDate(challenge))
      .subtract(-(challenge.period ?? 0), 'd')
      .locale('ru')
      .format('DD MMMM');
  }

  static getAwardsEndDate(challenge: ChallengeResponseModel): string {
    const awardsPeriod = (challenge.period ?? 0) + ChallengePeriodEnum.AWARDS_PERIOD;

    return moment(this.getStartDate(challenge))
      .subtract(-awardsPeriod, 'd')
      .locale('ru')
      .format('DD MMMM');
  }

  static getStartDate(challenge: ChallengeResponseModel): string | undefined | null {
    return challenge.tournament?.startDate ?? challenge.createdAt;
  }
}

export default ChallengeHelper;
