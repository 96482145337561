import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AInput = _resolveComponent("AInput")!
  const _component_AFormItem = _resolveComponent("AFormItem")!
  const _component_ASelectOption = _resolveComponent("ASelectOption")!
  const _component_ASelect = _resolveComponent("ASelect")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_AForm = _resolveComponent("AForm")!

  return (_openBlock(), _createElementBlock("div", {
    ref: "wrapper",
    class: "challenge-form",
    onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.handleClickOutsideForm && _ctx.handleClickOutsideForm(...args)))
  }, [
    _createVNode(_component_AForm, {
      ref: "formRef",
      model: _ctx.state.formData,
      rules: _ctx.rules,
      style: _normalizeStyle(_ctx.getStyle()),
      layout: "vertical"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_AFormItem, {
          label: "Введи хэштег своей Цели",
          name: "authorHash"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_AInput, {
              value: _ctx.state.formData.authorHash,
              "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.state.formData.authorHash) = $event))
            }, null, 8, ["value"])
          ]),
          _: 1
        }),
        (!!_ctx.state.formData.authorHash)
          ? (_openBlock(), _createBlock(_component_AFormItem, {
              key: 0,
              label: "Введи хэштег Цели соперника",
              name: "invitedUserHash"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_AInput, {
                  value: _ctx.state.formData.invitedUserHash,
                  "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.state.formData.invitedUserHash) = $event))
                }, null, 8, ["value"])
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (!!_ctx.state.formData.authorHash && !!_ctx.state.formData.invitedUserHash)
          ? (_openBlock(), _createBlock(_component_AFormItem, {
              key: 1,
              label: "Какой опыт у твоего соперника?"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ASelect, {
                  value: _ctx.state.formData.isNew,
                  "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.state.formData.isNew) = $event))
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.variants, (variant, index) => {
                      return (_openBlock(), _createBlock(_component_ASelectOption, {
                        key: `variant${index}`,
                        value: variant.value
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(variant.title), 1)
                        ]),
                        _: 2
                      }, 1032, ["value"]))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["value"])
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (!!_ctx.state.formData.authorHash && !!_ctx.state.formData.invitedUserHash)
          ? (_openBlock(), _createBlock(_component_AFormItem, {
              key: 2,
              label: "Ставка"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_AInput, {
                  value: _ctx.state.formData.bet,
                  "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.state.formData.bet) = $event))
                }, null, 8, ["value"])
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        _createVNode(_component_AFormItem, null, {
          default: _withCtx(() => [
            _createVNode(_component_a_button, {
              disabled: _ctx.isSubmitDisabled,
              class: "match-form__submit",
              "html-type": "submit",
              type: "primary",
              onClick: _ctx.onSubmit
            }, {
              default: _withCtx(() => [
                _createTextVNode(" Готово ")
              ]),
              _: 1
            }, 8, ["disabled", "onClick"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["model", "rules", "style"])
  ], 512))
}