<template>
  <template
    v-if="!hasRing"
  >
    <div
      class="ring-info-widget__text-block_column"
    >
      <LinkTemplate
        text-before="см. "
        link-text="Важный раздел"
        text-after=", раздел:"
        @link-click="handleImportantSectionLinkClick"
      />
      <a-typography-text>
        Как повысить уровень мастерства?
      </a-typography-text>
    </div>
  </template>
  <template
    v-else-if="isBlackRing"
  >
    <div
      class="ring-info-widget__text-block_column"
    >
      <a-typography-text
      >
        Ты - чемпион по реальным действиям
      </a-typography-text>
      <a-typography-text
      >
        и на верном пути!
      </a-typography-text>
    </div>
  </template>
  <template
    v-else
  >
    <a-typography-text
    >
      Для получения перстня достаточно
    </a-typography-text>
    <a-typography-text
    >
      подать заявку
    </a-typography-text>
    <div
      class="ring-info-widget__target-list"
    >
      <a-typography-text
      >
        До повышения перстня осталось:
      </a-typography-text>
      <ul>
        <li
          v-for="(targetLine, index) in targetList"
          :key="`targetList${index}`"
        >
          <a-typography-text>
            {{ targetLine }}
          </a-typography-text>
        </li>
      </ul>
    </div>
  </template>
</template>

<script
  lang="ts"
>
import {
  computed,
  defineComponent,
  inject,
  PropType,
} from 'vue';
import {
  TypographyText,
} from 'ant-design-vue';
import UserInfoResponse from '@/shared/Api/Model/UserInfoApi/UserInfoResponse';
import ServiceContainerInterface
  from '@/app/Services/ServiceContainer/Contract/ServiceContainerInterface';
import RingHelper from '@/shared/Helpers/RingHelper';
import WordDeclensionHelper from '@/shared/Helpers/WordDeclensionHelper';
import LinkTemplate from '@/shared/Ui/LinkTemplate.vue';

export default defineComponent({
  components: {
    ATypographyText: TypographyText,
    LinkTemplate,
  },
  props: {
    user: {
      type: Object as PropType<UserInfoResponse | null>,
      required: true,
    },
  },
  setup(props) {
    const serviceContainer = inject<ServiceContainerInterface>('serviceContainer');

    if (!serviceContainer) {
      throw new Error('serviceContainer not injected');
    }

    const hasRing = computed(() => !!props.user?.ring);

    const isBlackRing = computed(() => props.user && RingHelper.isBlack(props.user));

    const targetList = computed<Array<string>>(() => {
      if (!props.user) {
        return [];
      }

      const tournaments = RingHelper.getRemainingTournaments(props.user);
      const challenges = RingHelper.getRemainingChallenges(props.user);
      const you = RingHelper.getRemainingYou(props.user);
      const days = RingHelper.getRemainingDays(props.user);

      return [
        `${tournaments} ${WordDeclensionHelper.getTournamentDeclension(tournaments)}`,
        `${challenges} ${WordDeclensionHelper.getChallengeDeclension(challenges)}`,
        `${you} YOU`,
        `еще ${days ?? 0} ${WordDeclensionHelper.getDayDeclension(days ?? 0)} в YouFC`,
      ];
    });

    async function handleImportantSectionLinkClick() {
      if (!serviceContainer) {
        return;
      }

      const importantSectionLink = await serviceContainer
        .externalLinkService
        .getTelegramImportantSection();

      window.open(importantSectionLink);
    }

    return {
      hasRing,
      isBlackRing,
      targetList,
      handleImportantSectionLinkClick,
    };
  },
});
</script>

<style
  scoped
  lang="scss"
>
.ring-info-widget {
  &__target-list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__text-block {
    &_column {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }
}
</style>
