<template>
  <div
    class="freeline-statistics-widget__text-block_column"
  >
    <a-typography-text>
      Всего турниров {{ tournamentsCount }}
    </a-typography-text>
    <a-typography-text>
      Всего выполненных целей-челленджей {{ challengesCount }}
    </a-typography-text>
    <a-typography-text
      class="freeline-statistics-widget__additional-info"
    >
      <span
        class="freeline-statistics-widget__additional-info_super"
      >
        *
      </span>
      Раздел статистики будет пополняться.
    </a-typography-text>
  </div>
</template>

<script
  lang="ts"
>
import {
  inject,
  defineComponent,
  PropType, computed,
} from 'vue';
import {
  TypographyText,
} from 'ant-design-vue';
import UserInfoResponse from '@/shared/Api/Model/UserInfoApi/UserInfoResponse';
import ServiceContainerInterface
  from '@/app/Services/ServiceContainer/Contract/ServiceContainerInterface';

export default defineComponent({
  components: {
    ATypographyText: TypographyText,
  },
  props: {
    user: {
      type: Object as PropType<UserInfoResponse | null>,
      required: true,
    },
  },
  setup(props) {
    const serviceContainer = inject<ServiceContainerInterface>('serviceContainer');

    if (!serviceContainer) {
      throw new Error('serviceContainer not injected');
    }

    const tournamentsCount = computed(() => {
      if (!props.user) {
        return 0;
      }

      return props.user.tournaments.length;
    });

    const challengesCount = computed(() => {
      if (!props.user) {
        return 0;
      }

      return props.user.challenges.filter((challenge) => challenge.success).length;
    });

    return {
      tournamentsCount,
      challengesCount,
    };
  },
});
</script>

<style
  scoped
  lang="scss"
>
.freeline-statistics-widget {
  &__additional-info {
    padding-top: 5px;
    font-style: italic;

    &_super {
      vertical-align: super;
    }
  }

  &__text-block {
    &_column {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }
}
</style>
