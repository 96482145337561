import { resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderSlot as _renderSlot, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5c2da654"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "row"
}
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "toolbar" }
const _hoisted_4 = { class: "toolbar__left" }
const _hoisted_5 = { class: "toolbar__right" }
const _hoisted_6 = {
  key: 1,
  class: "user-info"
}
const _hoisted_7 = ["src"]
const _hoisted_8 = {
  key: 1,
  class: "user-info__name"
}
const _hoisted_9 = { class: "row" }
const _hoisted_10 = { class: "content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_typography_text = _resolveComponent("a-typography-text")!
  const _directive_click_outside = _resolveDirective("click-outside")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.showToolbar)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", null, [
            (_ctx.showImage)
              ? _withDirectives((_openBlock(), _createElementBlock("img", {
                  key: 0,
                  src: _ctx.state.imageUrl,
                  alt: "Preview",
                  class: "preview__image"
                }, null, 8, _hoisted_2)), [
                  [_directive_click_outside, _ctx.handleClickOutside]
                ])
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_a_typography_text, { strong: true }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$route.meta.title), 1)
                ]),
                _: 1
              })
            ]),
            _createElementVNode("div", _hoisted_5, [
              (_ctx.user)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: "counter",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.state.showImage = !_ctx.state.showImage))
                  }, _toDisplayString(_ctx.emojiHelper.getFire()) + " " + _toDisplayString(_ctx.user.shockMode.counter), 1))
                : _createCommentVNode("", true),
              (_ctx.user)
                ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                    (_ctx.user.photo)
                      ? (_openBlock(), _createElementBlock("img", {
                          key: 0,
                          src: _ctx.user.photo,
                          class: "user-info__photo",
                          alt: ""
                        }, null, 8, _hoisted_7))
                      : (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(_ctx.userName), 1))
                  ]))
                : _createCommentVNode("", true)
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_9, [
      _createElementVNode("div", _hoisted_10, [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ])
    ])
  ], 64))
}