import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-612214f4"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "ring-info-widget__text-block_column"
}
const _hoisted_2 = {
  key: 1,
  class: "ring-info-widget__text-block_column"
}
const _hoisted_3 = { class: "ring-info-widget__target-list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LinkTemplate = _resolveComponent("LinkTemplate")!
  const _component_a_typography_text = _resolveComponent("a-typography-text")!

  return (!_ctx.hasRing)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_LinkTemplate, {
          "text-before": "см. ",
          "link-text": "Важный раздел",
          "text-after": ", раздел:",
          onLinkClick: _ctx.handleImportantSectionLinkClick
        }, null, 8, ["onLinkClick"]),
        _createVNode(_component_a_typography_text, null, {
          default: _withCtx(() => [
            _createTextVNode(" Как повысить уровень мастерства? ")
          ]),
          _: 1
        })
      ]))
    : (_ctx.isBlackRing)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_a_typography_text, null, {
            default: _withCtx(() => [
              _createTextVNode(" Ты - чемпион по реальным действиям ")
            ]),
            _: 1
          }),
          _createVNode(_component_a_typography_text, null, {
            default: _withCtx(() => [
              _createTextVNode(" и на верном пути! ")
            ]),
            _: 1
          })
        ]))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
          _createVNode(_component_a_typography_text, null, {
            default: _withCtx(() => [
              _createTextVNode(" Для получения перстня достаточно ")
            ]),
            _: 1
          }),
          _createVNode(_component_a_typography_text, null, {
            default: _withCtx(() => [
              _createTextVNode(" подать заявку ")
            ]),
            _: 1
          }),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_a_typography_text, null, {
              default: _withCtx(() => [
                _createTextVNode(" До повышения перстня осталось: ")
              ]),
              _: 1
            }),
            _createElementVNode("ul", null, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.targetList, (targetLine, index) => {
                return (_openBlock(), _createElementBlock("li", {
                  key: `targetList${index}`
                }, [
                  _createVNode(_component_a_typography_text, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(targetLine), 1)
                    ]),
                    _: 2
                  }, 1024)
                ]))
              }), 128))
            ])
          ])
        ], 64))
}