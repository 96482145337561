<template>
  <div
    ref="widget"
  />
</template>

<script
  lang="ts"
>

import {
  defineComponent,
  onMounted,
  PropType,
  ref,
} from 'vue';
import Mode from './lib/Enum/Mode';
import RequestAccess from './lib/Enum/RequestAccess';
import Size from './lib/Enum/Size';

export default defineComponent({
  props: {
    mode: {
      type: String as PropType<Mode>,
      default: Mode.REDIRECT,
    },
    botName: {
      type: String,
      required: true,
    },
    redirectUrl: {
      type: String,
      default: '',
    },
    requestAccess: {
      type: String as PropType<RequestAccess>,
      default: RequestAccess.READ,
    },
    size: {
      type: String as PropType<Size>,
      default: Size.LARGE,
    },
    radius: {
      type: String,
      default: null,
    },
    isUserImage: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, { emit }) {
    const widget = ref();

    onMounted(() => {
      let mode = null;

      if (props.mode === Mode.CALLBACK) {
        mode = Mode.CALLBACK;
      } else if (props.mode === Mode.REDIRECT && props.redirectUrl) {
        mode = Mode.REDIRECT;
      }

      if (!mode) {
        return;
      }

      const script = document.createElement('script');
      script.async = true;
      script.src = 'https://telegram.org/js/telegram-widget.js?22';

      script.setAttribute('data-size', props.size);
      script.setAttribute('data-userpic', `${props.isUserImage}`);
      script.setAttribute('data-telegram-login', props.botName);
      script.setAttribute('data-request-access', props.requestAccess);

      if (props.radius) {
        script.setAttribute('data-radius', props.radius);
      }

      if (mode === Mode.CALLBACK) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        window.onTelegramAuth = (user: unknown) => {
          emit('onTelegramAuth', user);
        };

        script.setAttribute('data-onauth', 'window.onTelegramAuth(user)');
      } else {
        script.setAttribute('data-auth-url', props.redirectUrl);
      }

      widget.value.appendChild(script);
    });

    return {
      widget,
    };
  },
});

</script>

<style scoped>

</style>
