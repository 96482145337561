<template>
  <a-card
    v-if="state.buttonLink"
    hoverable
    class="success-card"
  >
    <p>
      {{ state.text }}
    </p>
    <a-button
      @click="handleGoToChatClick"
    >
      {{ state.buttonTitle }}
    </a-button>
  </a-card>
</template>

<script
  lang="ts"
>
import {
  defineComponent, inject, onMounted, reactive,
} from 'vue';
import ServiceContainerInterface from '@/app/Services/ServiceContainer/Contract/ServiceContainerInterface';

export default defineComponent({
  name: 'ChallengeCreateSuccessPage',
  setup() {
    const serviceContainer = inject<ServiceContainerInterface>('serviceContainer');

    if (!serviceContainer) {
      throw new Error('serviceContainer not injected');
    }

    const {
      apiService,
      challengeService,
    } = serviceContainer;

    const state = reactive<{
      buttonTitle: string | null,
      text: string | null,
      buttonLink: string | null,
    }>({
      buttonTitle: 'Перейти в чат',
      text: 'Поздравляем! Твоя Цель-челлендж после одобрения будет опубликована в чате для отчётов и можно будет приступать к ее выполнению!',
      buttonLink: null,
    });

    onMounted(async () => {
      const response = await apiService.linksApi.list();

      state.buttonLink = response.challengeCreateSuccess;

      challengeService.create();
    });

    async function handleGoToChatClick() {
      window.location.href = state.buttonLink as string;
    }

    return {
      state,
      handleGoToChatClick,
    };
  },
});
</script>

<style
  lang="scss"
>
.success-card {
  width: 300px;
  margin: 0 auto;
}
</style>
