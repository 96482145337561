import { InternalAxiosRequestConfig } from 'axios';
import { Store } from 'vuex';
import AuthorizationServiceInterface
  from '@/app/Services/Authorization/Contract/AuthorizationServiceInterface';
import LocalStorageKeysEnum from '@/app/Services/LocalStorage/Enum/LocalStorageKeysEnum';
import LocalStorageServiceInterface
  from '@/app/Services/LocalStorage/Contract/LocalStorageServiceInterface';
import ApiServiceInterface from '@/shared/Api/Contract/ApiServiceInterface';
import UtilsServiceInterface from '@/app/Services/UtilsService/Contract/UtilsServiceInterface';

class AuthorizationService implements AuthorizationServiceInterface {
  private readonly apiService: ApiServiceInterface;

  private localStorageService: LocalStorageServiceInterface;

  private readonly store: Store<unknown>;

  private utilsService: UtilsServiceInterface;

  constructor(
    apiService: ApiServiceInterface,
    localStorageService: LocalStorageServiceInterface,
    store: Store<unknown>,
    utilsService: UtilsServiceInterface,
  ) {
    this.utilsService = utilsService;
    this.store = store;
    this.localStorageService = localStorageService;
    this.apiService = apiService;
  }

  auth = async (code?: string | number): Promise<boolean> => {
    if (
      this.utilsService.typeCheck.isUndefined(code)
      || !this.utilsService.typeCheck.isString(code)
    ) {
      await this.redirectToMain();

      return Promise.resolve(false);
    }

    try {
      const response = await this.apiService.authorizationApi.auth(code);

      this.localStorageService.write(LocalStorageKeysEnum.TOKEN, response.token);

      this.localStorageService.write(LocalStorageKeysEnum.REFRESH_TOKEN, response.refreshToken);

      await this.store.dispatch('updateToken', response.token);

      await this.store.dispatch('updateRefreshToken', response.refreshToken);

      await this.store.dispatch('updateUser');

      return Promise.resolve(true);
    } catch (e) {
      await this.redirectToMain();

      return Promise.resolve(false);
    }
  };

  authByCode = async (code?: string | number): Promise<boolean> => {
    if (
      this.utilsService.typeCheck.isUndefined(code)
      || !this.utilsService.typeCheck.isString(code)
    ) {
      await this.redirectToMain();

      return Promise.resolve(false);
    }

    try {
      const response = await this.apiService.authorizationApi.authByCode(code);

      this.localStorageService.write(LocalStorageKeysEnum.TOKEN, response.token);

      this.localStorageService.write(LocalStorageKeysEnum.REFRESH_TOKEN, response.refreshToken);

      await this.store.dispatch('updateToken', response.token);

      await this.store.dispatch('updateRefreshToken', response.refreshToken);

      await this.store.dispatch('updateUser');

      return Promise.resolve(true);
    } catch (e) {
      await this.redirectToMain();

      return Promise.resolve(false);
    }
  };

  refreshTokenPair = async (): Promise<boolean> => {
    const refreshToken = this.store.getters.getRefreshToken;

    if (!refreshToken) {
      return Promise.resolve(false);
    }
    try {
      const response = await this.apiService.authorizationApi
        .refresh(refreshToken);

      this.localStorageService.write(LocalStorageKeysEnum.TOKEN, response.token);

      this.localStorageService.write(LocalStorageKeysEnum.REFRESH_TOKEN, response.refreshToken);

      await this.store.dispatch('updateToken', response.token);

      await this.store.dispatch('updateRefreshToken', response.refreshToken);

      await this.store.dispatch('updateUser');
    } catch (e) {
      await this.logout();

      await this.redirectToMain();

      return Promise.resolve(false);
    }

    return Promise.resolve(true);
  };

  signRequest = (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
    const token = this.store.getters.getToken;

    config.headers.Authorization = token ? `Bearer ${token}` : null;

    return config;
  };

  isAuthorised = (): boolean => !!this.localStorageService.read(
    LocalStorageKeysEnum.TOKEN,
  );

  async initSession(): Promise<void> {
    const token = this.localStorageService.read(LocalStorageKeysEnum.TOKEN);
    const refreshToken = this.localStorageService.read(LocalStorageKeysEnum.REFRESH_TOKEN);

    if (token) {
      await this.store.dispatch('updateToken', token);
    }

    if (refreshToken) {
      await this.store.dispatch('updateRefreshToken', refreshToken);
    }
  }

  private redirectToMain() {
    window.location.href = '/';

    return Promise.resolve();
  }

  logout(): Promise<void> {
    this.localStorageService.remove(LocalStorageKeysEnum.TOKEN);
    this.localStorageService.remove(LocalStorageKeysEnum.REFRESH_TOKEN);

    return Promise.resolve();
  }
}

export default AuthorizationService;
