import {
  Directive,
} from '@vue/runtime-core';
import ElementHelper from '@/app/directive/ElementHelper';

let handler: (event: MouseEvent) => void;
let init = false;

const clickOutside: Directive = {
  mounted(el, binding) {
    handler = (event: MouseEvent) => {
      event.stopPropagation();

      if (!init) {
        init = true;

        return;
      }

      if (!ElementHelper.isCursorInsideElement(el, event)) {
        binding.value(event, el);
      }
    };

    document.addEventListener('click', handler);
  },

  beforeUnmount() {
    if (handler) {
      init = false;
      document.removeEventListener('click', handler);
    }
  },
};

export default clickOutside;
