import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_typography_text = _resolveComponent("a-typography-text")!

  return (_openBlock(), _createBlock(_component_a_typography_text, null, {
    default: _withCtx(() => [
      (_ctx.textBefore)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createTextVNode(_toDisplayString(_ctx.textBefore), 1)
          ], 64))
        : _createCommentVNode("", true),
      _createVNode(_component_a_button, {
        type: "link",
        class: "link",
        onClick: _ctx.handleLinkClick
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.linkText), 1)
        ]),
        _: 1
      }, 8, ["onClick"]),
      (_ctx.textAfter)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            _createTextVNode(_toDisplayString(_ctx.textAfter), 1)
          ], 64))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}