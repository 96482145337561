import LocalStorageKeysEnum from '@/app/Services/LocalStorage/Enum/LocalStorageKeysEnum';

import ChallengeCreateDataModel from '@/entities/Challenge/ChallengeCreateDataModel';
import ServiceContainerInterface from '@/app/Services/ServiceContainer/Contract/ServiceContainerInterface';

import ChallengeResponseModel from '@/shared/Api/Model/Challenges/ChallengeResponseModel';
import ChallengeServiceInterface from './Contract/ChallengeServiceInterface';

class ChallengeService implements ChallengeServiceInterface {
  private readonly serviceContainer: ServiceContainerInterface;

  constructor(
    serviceContainer: ServiceContainerInterface,
  ) {
    this.serviceContainer = serviceContainer;
  }

  async create(): Promise<void> {
    const {
      apiService,
      localStorageService,
    } = this.serviceContainer;

    const data = localStorageService.read<ChallengeCreateDataModel>(LocalStorageKeysEnum.CHALLENGE_SAVE_DATA);

    if (data) {
      await apiService.userInfoApi.update({ fullName: data.userName });

      try {
        const challenge: ChallengeResponseModel = {
          description: data.description,
          reason: data.reason,
          bet: +data.bet,
          reportType: data.reportType,
          numberOfReports: +data.numberOfReports,
        };

        if (data.tournament) {
          challenge.tournament = data.tournament;
        }

        await apiService.challengesApi.create(challenge);

        localStorageService.remove(LocalStorageKeysEnum.CHALLENGE_SAVE_DATA);
      } catch {
        throw new Error('Challenge Service. Create challenge error');
      }
    }
  }
}

export default ChallengeService;
