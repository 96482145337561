<template>
  <a-typography-text
  >
    <template
      v-if="textBefore"
    >
      {{ textBefore }}
    </template>
    <a-button
      type="link"
      class="link"
      @click="handleLinkClick"
    >
      {{ linkText }}
    </a-button>
    <template
      v-if="textAfter"
    >
      {{ textAfter }}
    </template>
  </a-typography-text>
</template>

<script
  lang="ts"
>
import {
  defineComponent,
} from 'vue';
import {
  Button,
  TypographyText,
} from 'ant-design-vue';

export default defineComponent({
  props: {
    textBefore: {
      type: String,
      default: () => null,
    },
    linkText: {
      type: String,
      required: true,
    },
    textAfter: {
      type: String,
      default: () => null,
    },
  },
  emits: [
    'link-click',
  ],
  components: {
    AButton: Button,
    ATypographyText: TypographyText,
  },
  setup(_props, { emit }) {
    function handleLinkClick() {
      emit('link-click');
    }

    return {
      handleLinkClick,
    };
  },
});
</script>

<style
  scoped
  lang="scss"
>
.link {
  padding: 0;
}
</style>
