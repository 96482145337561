import ChallengesApiInterface from '@/shared/Api/Contract/Api/ChallengesApiInterface';
import ChallengeResponseModel from '@/shared/Api/Model/Challenges/ChallengeResponseModel';
import AxiosClientServiceInterface
  from '@/app/Services/AxiosClientService/Contract/AxiosClientServiceInterface';
import moment from 'moment';
import CreateImageRequestModel from '@/shared/Api/Model/Challenges/CreateImageRequestModel';

class ChallengesApi implements ChallengesApiInterface {
  private clientService: AxiosClientServiceInterface;

  constructor(
    clientService: AxiosClientServiceInterface,
  ) {
    this.clientService = clientService;
  }

  async get(userId?: number): Promise<ChallengeResponseModel[]> {
    const filter: {
      'createdAt[after]': Date,
      'user.id'?: number,
    } = {
      'createdAt[after]': moment().subtract(14, 'd').toDate(),
    };

    if (userId) {
      filter['user.id'] = userId;
    }

    const response = await this.clientService.instance
      .get<{ 'hydra:member': ChallengeResponseModel[] }>(
        '/challenges',
        {
          params: filter,
          headers: {
            Accept: 'application/ld+json',
            'Content-Type': 'application/ld+json',
          },
        },
      );

    return response.data['hydra:member']
      .filter((challenge) => !challenge.deleted) ?? [];
  }

  async create(entity: ChallengeResponseModel): Promise<ChallengeResponseModel> {
    return this.clientService.instance.post('/challenge', entity)
      .then((response) => response.data);
  }

  async getActual(): Promise<Array<{ hash: string; id: number; expired: boolean; user: { id: number; }; }>> {
    const response = await this.clientService.instance
      .get('/challenge/actual');

    return response.data;
  }

  async createImage(options: CreateImageRequestModel): Promise<Blob> {
    return this.clientService.instance.post('/v2/challenge/createImage', options, {
      headers: {
        Accept: 'image/jpeg',
      },
      responseType: 'arraybuffer',
    })
      .then((response) => new Blob([response.data]));
  }
}

export default ChallengesApi;
