<template>
  <a-card>
    <div
      class="promo-code"
    >
      <template
        v-if="!state.codeInputFormShow"
      >
        <a-typography-text
          class="promo-code__header"
        >
          У вас есть Инвайт/Промо код?
        </a-typography-text>
        <a-button
          block
          @click="onOpenInputCode"
        >
          Ввести код
        </a-button>
        <a-button
          block
          @click="onRejectCode"
        >
          Продолжу без кода
        </a-button>
      </template>
      <template
        v-else
      >
        <a-typography-text>
          Введите код
        </a-typography-text>

        <a-input
          v-model:value="state.code"
          @input="changeCodeValue"
        />
        <a-button
          block
          @click="apply"
        >
          Отправить код
        </a-button>
      </template>
    </div>
  </a-card>
</template>

<script
  lang="ts"
>
import {
  defineComponent,
  reactive,
} from 'vue';

interface IState {
  codeInputFormShow: boolean;
  code: string;
}

export default defineComponent({
  name: 'PromoCodeWidget',

  setup(_props, { emit }) {
    const state: IState = reactive({
      codeInputFormShow: false,
      code: '',
    });

    function onOpenInputCode() {
      state.codeInputFormShow = true;
    }

    function onRejectCode() {
      state.codeInputFormShow = false;
      emit('rejectCode');
    }

    function apply() {
      state.codeInputFormShow = false;
      emit('submitCode', state.code);

      state.code = '';
    }

    function changeCodeValue() {
      state.code = state.code.toUpperCase();
    }

    return {
      state,
      onOpenInputCode,
      onRejectCode,
      apply,
      changeCodeValue,
    };
  },
});
</script>

<style
  scoped
  lang="scss"
>
.promo-code {
  display: flex;
  width: 100%;
  gap: 10px;
  flex-direction: column;

  &__header {
    margin-bottom: 20px;
  }
}
</style>
