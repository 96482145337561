import ReportTypeApiInterface from '@/shared/Api/Contract/Api/ReportTypeApiInterface';
import AxiosClientServiceInterface
  from '@/app/Services/AxiosClientService/Contract/AxiosClientServiceInterface';
import ReportTypeResponse from '@/shared/Api/Model/ReportType/ReportTypeResponse';

class ReportTypeApi implements ReportTypeApiInterface {
  private clientService: AxiosClientServiceInterface;

  constructor(
    clientService: AxiosClientServiceInterface,
  ) {
    this.clientService = clientService;
  }

  list(): Promise<ReportTypeResponse[]> {
    return this.clientService.instance.get('/report_types', {
      headers: {
        Accept: 'application/ld+json',
        'Content-Type': 'application/ld+json',
      },
    })
      .then((response) => response.data['hydra:member']);
  }
}

export default ReportTypeApi;
