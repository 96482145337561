import AxiosClientServiceInterface
  from '@/app/Services/AxiosClientService/Contract/AxiosClientServiceInterface';
import UserInfoApiInterface from '@/shared/Api/Contract/Api/UserInfoApiInterface';
import UserInfoResponse from '@/shared/Api/Model/UserInfoApi/UserInfoResponse';

class UserInfoApi implements UserInfoApiInterface {
  private clientService: AxiosClientServiceInterface;

  constructor(
    clientService: AxiosClientServiceInterface,
  ) {
    this.clientService = clientService;
  }

  async getInfo(): Promise<UserInfoResponse> {
    return this.clientService.instance
      .get<UserInfoResponse>('/user/info')
      .then((response) => response.data);
  }

  async update(info: { fullName: string }): Promise<UserInfoResponse> {
    return this.clientService.instance
      .patch<UserInfoResponse>('/user/info', info)
      .then((response) => response.data);
  }
}

export default UserInfoApi;
