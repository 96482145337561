import TelegramBotApiInterface from '@/shared/Api/Contract/Api/TelegramBotApiInterface';
import AxiosClientServiceInterface
  from '@/app/Services/AxiosClientService/Contract/AxiosClientServiceInterface';
import AuthTelegramBotResponse
  from '@/shared/Api/Model/TelegramBotInfoApi/AuthTelegramBotResponse';

class TelegramBotApi implements TelegramBotApiInterface {
  private clientService: AxiosClientServiceInterface;

  constructor(
    clientService: AxiosClientServiceInterface,
  ) {
    this.clientService = clientService;
  }

  async getAuthBot(): Promise<AuthTelegramBotResponse> {
    return this.clientService.instance
      .get<AuthTelegramBotResponse>('/telegram-bot/info')
      .then((response) => response.data);
  }
}

export default TelegramBotApi;
