<template>
  <LayoutComponent
    :user="user"
  >
    <router-view/>
  </LayoutComponent>
</template>
<script
  lang="ts"
>
import {
  computed,
  defineComponent,
  inject,
  onMounted,
} from 'vue';
import ServiceContainerInterface
  from '@/app/Services/ServiceContainer/Contract/ServiceContainerInterface';
import LayoutComponent from '@/app/Component/LayoutComponent.vue';

export default defineComponent({
  components: {
    LayoutComponent,
  },
  setup() {
    const serviceContainer = inject<ServiceContainerInterface>('serviceContainer');

    if (!serviceContainer) {
      throw new Error('serviceContainer not injected');
    }

    const user = computed(() => serviceContainer.store.getters.getUser);

    onMounted(async () => {
      await Promise.all([
        serviceContainer.store.dispatch('updateAuthBot'),
      ]);
    });

    return {
      user,
    };
  },
});
</script>
