<template>
  <div
    class="home-page"
  >
    <a-typography-title
      :level="3"
      class="home-page__title"
    >
      YouFC — полезная соцсеть для  полезных действий
    </a-typography-title>
    <a-typography-paragraph>
      Заходи и качество твоей жизни повысится!
    </a-typography-paragraph>
    <TelegramAuthWidget
      v-if="!user && authBot"
      mode="redirect"
      :bot-name="authBot.name"
      :redirect-url="authBot.redirectUri"
    />
  </div>
</template>
<script
  lang="ts"
>
import TelegramAuthWidget from '@/widgets/TelegramAuthWidget/TelegramAuthWidget.vue';
import {
  computed,
  defineComponent,
  inject,
} from 'vue';
import ServiceContainerInterface
  from '@/app/Services/ServiceContainer/Contract/ServiceContainerInterface';

export default defineComponent({
  components: {
    TelegramAuthWidget,
  },
  setup() {
    const serviceContainer = inject<ServiceContainerInterface>('serviceContainer');

    if (!serviceContainer) {
      throw new Error('serviceContainer not injected');
    }

    const user = computed(() => serviceContainer.store.getters.getUser);

    const authBot = computed(() => serviceContainer.store.getters.getAuthBot);

    return {
      user,
      authBot,
    };
  },
});
</script>

<style
  lang="scss"
>
.home-page {
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 200px;

  &__title {
    text-align: center;
  }
}
</style>
