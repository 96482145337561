import AxiosClientServiceInterface
  from '@/app/Services/AxiosClientService/Contract/AxiosClientServiceInterface';
import ClubModel from '@/shared/Api/Model/RPC/Club/ClubModel';
import AddToUserMethodInterface
  from '@/shared/Api/Contract/Api/RPC/Club/AddToUserMethodInterface';

class AddToUserMethod implements AddToUserMethodInterface {
  private readonly client: AxiosClientServiceInterface;

  constructor(
    client: AxiosClientServiceInterface,
  ) {
    this.client = client;
  }

  async call(club: ClubModel): Promise<ClubModel> {
    return this.client.instance.post<ClubModel>('/v2/club/addToUser', club)
      .then((response) => response.data);
  }
}

export default AddToUserMethod;
