import EmojiEnum from '@/entities/EmojiEnum';

class EmojiHelper {
  static getCup(): EmojiEnum {
    return EmojiEnum.CUP;
  }

  static getFire(): EmojiEnum {
    return EmojiEnum.FIRE;
  }
}

export default EmojiHelper;
