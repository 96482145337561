import ListActualMethodInterface
  from '@/shared/Api/Contract/Api/RPC/ReportType/ListActualMethodInterface';
import AxiosClientServiceInterface
  from '@/app/Services/AxiosClientService/Contract/AxiosClientServiceInterface';
import ReportTypeModel from '@/shared/Api/Model/RPC/ReportType/ReportTypeModel';

class ListActualMethod implements ListActualMethodInterface {
  private readonly client: AxiosClientServiceInterface;

  constructor(
    client: AxiosClientServiceInterface,
  ) {
    this.client = client;
  }

  async call(): Promise<ReportTypeModel[]> {
    return this.client.instance.post<ReportTypeModel[]>('/v2/report-type/listActual')
      .then((response) => response.data);
  }
}

export default ListActualMethod;
