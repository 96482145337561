import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-63c60315"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "challenge" }
const _hoisted_2 = { class: "challenge__row" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "challenge__row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_typography_text = _resolveComponent("a-typography-text")!
  const _component_LinkTemplate = _resolveComponent("LinkTemplate")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_a_typography_text, { strong: true }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.challenge.description), 1)
        ]),
        _: 1
      }),
      (_ctx.challenge.isTodayReport)
        ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.emojiHelper.getCup()), 1))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_a_typography_text, { copyable: "" }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.hash), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_a_typography_text, null, {
        default: _withCtx(() => [
          _createTextVNode(" До финиша " + _toDisplayString(_ctx.daysForFinish) + " " + _toDisplayString(_ctx.wordDeclensionHelper.getDayDeclension(_ctx.daysForFinish)), 1)
        ]),
        _: 1
      })
    ]),
    _createVNode(_component_a_typography_text, null, {
      default: _withCtx(() => [
        _createTextVNode(" Дата старта: " + _toDisplayString(_ctx.startDate), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_a_typography_text, null, {
      default: _withCtx(() => [
        _createTextVNode(" Дата финиша: " + _toDisplayString(_ctx.endDate), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_a_typography_text, null, {
      default: _withCtx(() => [
        _createTextVNode(" Всего отчетов в РК: " + _toDisplayString(_ctx.reportsCount) + " / " + _toDisplayString(_ctx.totalReportsCount), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_a_typography_text, null, {
      default: _withCtx(() => [
        _createTextVNode(" Забрать золото с " + _toDisplayString(_ctx.awardsStartDay) + " по " + _toDisplayString(_ctx.awardsEndDay) + " ", 1),
        _createVNode(_component_LinkTemplate, {
          "text-before": "в ",
          "link-text": "Главном чате",
          "text-after": ".",
          onLinkClick: _ctx.handleMainChatClick
        }, null, 8, ["onLinkClick"])
      ]),
      _: 1
    }),
    _createVNode(_component_a_typography_text, null, {
      default: _withCtx(() => [
        _createTextVNode(" Ставка: " + _toDisplayString(_ctx.bet), 1)
      ]),
      _: 1
    })
  ]))
}