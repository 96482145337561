import { createApp } from 'vue';
import Antd from 'ant-design-vue';
import ServiceContainer from '@/app/Services/ServiceContainer/ServiceContainer';
import clickOutside from '@/app/directive/click-outside';
import App from './App.vue';
import { getRouter } from './providers';

import 'ant-design-vue/dist/reset.css';

const serviceContainer = new ServiceContainer();

const router = getRouter(serviceContainer);

serviceContainer.authorizationService.initSession().then(() => {
  createApp(App)
    .use(Antd)
    .provide('serviceContainer', serviceContainer)
    .use(serviceContainer.store)
    .use(router)
    .directive('click-outside', clickOutside)
    .mount('#app');
});
