import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b1745b84"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "premium-widget__wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_typography_text = _resolveComponent("a-typography-text")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_card = _resolveComponent("a-card")!

  return (_openBlock(), _createBlock(_component_a_card, { class: "premium-widget" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_a_typography_text, null, {
          default: _withCtx(() => [
            _createTextVNode(" Премиум-фрилайнер получает больше! ")
          ]),
          _: 1
        }),
        _createVNode(_component_a_button, {
          type: "primary",
          onClick: _withModifiers(_ctx.goToPremium, ["stop"])
        }, {
          default: _withCtx(() => [
            _createTextVNode(" Попробовать ")
          ]),
          _: 1
        }, 8, ["onClick"])
      ])
    ]),
    _: 1
  }))
}