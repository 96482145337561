import TournamentListActualMethodInterface
  from '@/shared/Api/Contract/Api/RPC/Tournament/ListActualMethodInterface';
import TournamentListActualMethod from '@/shared/Api/Api/RPC/Tournament/ListActualMethod';
import ReportTypeListActualMethodInterface
  from '@/shared/Api/Contract/Api/RPC/ReportType/ListActualMethodInterface';
import ReportTypeListActualMethod from '@/shared/Api/Api/RPC/ReportType/ListActualMethod';
import ChallengeListActualMethodInterface
  from '@/shared/Api/Contract/Api/RPC/Challenge/ListActualMethodInterface';
import ChallengeListActualMethod from '@/shared/Api/Api/RPC/Challenge/ListActualMethod';
import AxiosClientServiceInterface
  from '@/app/Services/AxiosClientService/Contract/AxiosClientServiceInterface';
import ApplyMethodInterface
  from '@/shared/Api/Contract/Api/RPC/InvitationCode/ApplyMethodInterface';
import ApplyMethod from '@/shared/Api/Api/RPC/InvitationCode/ApplyMethod';
import RPCApiInterface from '@/shared/Api/Contract/Api/RPC/RPCApiInterface';
import AddToUserMethodInterface from '@/shared/Api/Contract/Api/RPC/Club/AddToUserMethodInterface';
import AddToUserMethod from '@/shared/Api/Api/RPC/Club/AddToUserMethod';

class RPCApi implements RPCApiInterface {
  private _tournament?: {
    listActual: TournamentListActualMethodInterface;
  };

  private _reportType?: {
    listActual: ReportTypeListActualMethodInterface;
  };

  private _challenge?: {
    listActual: ChallengeListActualMethodInterface;
  };

  private _invitationCode?: {
    apply: ApplyMethodInterface;
  };

  private _club?: {
    addToUser: AddToUserMethodInterface;
  };

  private readonly client: AxiosClientServiceInterface;

  constructor(
    client: AxiosClientServiceInterface,
  ) {
    this.client = client;
  }

  get tournament(): { listActual: TournamentListActualMethodInterface } {
    if (!this._tournament) {
      this._tournament = {
        listActual: new TournamentListActualMethod(this.client),
      };
    }

    return this._tournament;
  }

  get reportType(): { listActual: ReportTypeListActualMethodInterface } {
    if (!this._reportType) {
      this._reportType = {
        listActual: new ReportTypeListActualMethod(this.client),
      };
    }

    return this._reportType;
  }

  get challenge(): { listActual: ChallengeListActualMethodInterface } {
    if (!this._challenge) {
      this._challenge = {
        listActual: new ChallengeListActualMethod(this.client),
      };
    }

    return this._challenge;
  }

  get invitationCode(): { apply: ApplyMethodInterface } {
    if (!this._invitationCode) {
      this._invitationCode = {
        apply: new ApplyMethod(this.client),
      };
    }

    return this._invitationCode;
  }

  get club(): { addToUser: AddToUserMethodInterface } {
    if (!this._club) {
      this._club = {
        addToUser: new AddToUserMethod(this.client),
      };
    }

    return this._club;
  }
}

export default RPCApi;
