<template>
  <div
    ref="wrapper"
    class="challenge-form"
    @click="handleClickOutsideForm"
  >
    <AForm
      ref="formRef"
      :model="state.formData"
      :rules="rules"
      :style="getStyle()"
      layout="vertical"
    >
      <AFormItem
        label="Введи хэштег своей Цели"
        name="authorHash"
      >
        <AInput
          v-model:value="state.formData.authorHash"
        />
      </AFormItem>
      <AFormItem
        v-if="!!state.formData.authorHash"
        label="Введи хэштег Цели соперника"
        name="invitedUserHash"
      >
        <AInput
          v-model:value="state.formData.invitedUserHash"
        />
      </AFormItem>
      <AFormItem
        v-if="!!state.formData.authorHash && !!state.formData.invitedUserHash"
        label="Какой опыт у твоего соперника?"
      >
        <ASelect
          v-model:value="state.formData.isNew"
        >
          <ASelectOption
            v-for="(variant, index) in variants"
            :key="`variant${index}`"
            :value="variant.value"
          >
            {{ variant.title }}
          </ASelectOption>
        </ASelect>
      </AFormItem>
      <AFormItem
        v-if="!!state.formData.authorHash && !!state.formData.invitedUserHash"
        label="Ставка"
      >
        <AInput
          v-model:value="state.formData.bet"
        />
      </AFormItem>
      <AFormItem>
        <a-button
          :disabled="isSubmitDisabled"
          class="match-form__submit"
          html-type="submit"
          type="primary"
          @click="onSubmit"
        >
          Готово
        </a-button>
      </AFormItem>
    </AForm>
  </div>
</template>

<script
  lang="ts"
>
// eslint-disable-next-line import/no-extraneous-dependencies
import { reactive } from '@vue/runtime-core';
import {
  computed,
  defineComponent, inject, onMounted,
  ref, toRaw,
} from 'vue';
import {
  Form,
  FormItem,
  Input, notification,
  Select,
  SelectOption,
} from 'ant-design-vue';
import ServiceContainerInterface
  from '@/app/Services/ServiceContainer/Contract/ServiceContainerInterface';
import { Rule } from 'ant-design-vue/es/form';
import SendInvitationChallengeRequestModel
  from '@/shared/Api/Model/InvitaitonChallengeApi/SendInvitationChallengeRequestModel';

interface State {
  formData: {
    authorHash: string;
    invitedUserHash: string;
    isNew: 0 | 1;
    bet: number;
  };
  challengeList: Array<{ hash: string; id: number; expired: boolean; user: { id: number; }; }>;
}

export default defineComponent({
  components: {
    AForm: Form,
    AFormItem: FormItem,
    AInput: Input,
    ASelect: Select,
    ASelectOption: SelectOption,
  },
  setup(_props, { emit }) {
    const serviceContainer = inject<ServiceContainerInterface>('serviceContainer');
    const formRef = ref();
    const wrapper = ref();
    const state = reactive<State>({
      formData: {
        authorHash: '',
        invitedUserHash: '',
        isNew: 0,
        bet: 0,
      },
      challengeList: [],
    });

    const variants = [
      {
        title: 'Соперник впервые в YouFC',
        value: 1,
      },
      {
        title: 'Опытный соперник',
        value: 0,
      },
    ];

    function handleClickOutsideForm(event: PointerEvent) {
      if (event.target === wrapper.value) {
        emit('close');
      }
    }

    function getStyle() {
      return {
        width: '300px',
        'background-color': '#ffffff',
        height: 'max-content',
        padding: '20px',
        'border-radius': '8px',
      };
    }

    onMounted(async () => {
      const response = await serviceContainer?.apiService.challengesApi.getActual();

      if (!response) {
        return;
      }

      state.challengeList = response;
    });

    const checkAuthorsHash = async (_rule: Rule, value: string) => {
      const newValue = value.replace('#f', '#');

      const challenge = state.challengeList.find((challenge) => challenge.hash === newValue);

      if (!challenge) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject('Хештег не найден');
      }

      if (challenge.user.id !== serviceContainer?.store.getters.getUser.id) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject('Введи хештег твоей Цели.');
      }

      if (challenge.expired) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject('Цель-челлендж не активна.');
      }

      return Promise.resolve();
    };

    const checkInvitedUserHash = async (_rule: Rule, value: string) => {
      const newValue = value.replace('#f', '#');

      const challenge = state.challengeList.find((challenge) => challenge.hash === newValue);

      if (!challenge) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject('Хештег не найден');
      }

      if (challenge.user.id === serviceContainer?.store.getters.getUser.id) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject('Введи хештег Цели твоего соперника.');
      }

      if (challenge.expired) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject('Цель-челлендж не активна.');
      }

      return Promise.resolve();
    };

    const rules: Record<string, Rule[]> = {
      authorHash: [{ validator: checkAuthorsHash, trigger: 'blur' }],
      invitedUserHash: [{ validator: checkInvitedUserHash, trigger: 'blur' }],
    };

    const onSubmit = () => {
      formRef.value
        .validate()
        .then(async () => {
          await serviceContainer?.apiService.invitationChallengeApi.send(
            toRaw(state.formData)as unknown as SendInvitationChallengeRequestModel,
          ).then(
            () => notification.success({
              message: 'Успешно!',
              duration: 3,
            }),
          ).catch(
            () => notification.error({
              message: 'Ошибка соединения!',
              duration: 3,
            }),
          );

          emit('close');
        })
        .catch((error: Error) => {
          console.log('error', error);
        });
    };

    const resetForm = () => {
      formRef.value.resetFields();
    };

    const isSubmitDisabled = computed(
      () => !state.formData.authorHash
        || !state.formData.invitedUserHash
        || state.formData.isNew === null,
    );

    return {
      handleClickOutsideForm,
      state,
      wrapper,
      formRef,
      variants,
      getStyle,
      rules,
      onSubmit,
      resetForm,
      isSubmitDisabled,
    };
  },
});
</script>

<style
  scoped
  lang="scss"
>
.challenge-button {
  display: block;
  width: 100%;
  color: #1677ff;
  border: 1px solid #1677ff;
  border-radius: 8px;
  padding: 10px 0;
  background-color: transparent;
  cursor: pointer;

  &:disabled {
    color: #a3a3a3;
    border: 1px solid #a3a3a3;
  }

  &:hover {
    color: #a3a3a3;
    border: 1px solid #a3a3a3;
  }
}

.challenge-form {
  display: flex;
  justify-content: center;
  padding-top: 100px;
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, .2);
  z-index: 1000;
}
</style>
