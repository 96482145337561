import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-680fc6fe"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "user-summary-widget" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_typography_text = _resolveComponent("a-typography-text")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_typography_text, { strong: true }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.userSummary.ring), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_a_typography_text, { strong: true }, {
      default: _withCtx(() => [
        _createTextVNode(" Баланс YOU ")
      ]),
      _: 1
    }),
    _createVNode(_component_a_typography_text, null, {
      default: _withCtx(() => [
        _createTextVNode(" Рейтинг: " + _toDisplayString(_ctx.userSummary.rating), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_a_typography_text, null, {
      default: _withCtx(() => [
        _createTextVNode(" Доступно: " + _toDisplayString(_ctx.userSummary.credit), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_a_typography_text, { strong: true }, {
      default: _withCtx(() => [
        _createTextVNode(" Золотые жетоны ")
      ]),
      _: 1
    }),
    _createVNode(_component_a_typography_text, null, {
      default: _withCtx(() => [
        _createTextVNode(" Доступно: " + _toDisplayString(_ctx.userSummary.goldCoins), 1)
      ]),
      _: 1
    })
  ]))
}