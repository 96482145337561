import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_card = _resolveComponent("a-card")!

  return (_ctx.state.buttonLink)
    ? (_openBlock(), _createBlock(_component_a_card, {
        key: 0,
        hoverable: "",
        class: "success-card"
      }, {
        default: _withCtx(() => [
          _createElementVNode("p", null, _toDisplayString(_ctx.state.text), 1),
          _createVNode(_component_a_button, { onClick: _ctx.handleGoToChatClick }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.state.buttonTitle), 1)
            ]),
            _: 1
          }, 8, ["onClick"])
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}