import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8e1a5642"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "friends-widget__header" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_typography_text = _resolveComponent("a-typography-text")!
  const _component_LinkTemplate = _resolveComponent("LinkTemplate")!
  const _component_a_button = _resolveComponent("a-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.hasLink)
      ? (_openBlock(), _createBlock(_component_a_typography_text, {
          key: 0,
          copyable: ""
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.user.link), 1)
          ]),
          _: 1
        }))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createVNode(_component_LinkTemplate, {
            "text-before": "Получи ссылку в ",
            "link-text": "Важном разделе",
            "text-after": ","
          }),
          _createVNode(_component_a_typography_text, null, {
            default: _withCtx(() => [
              _createTextVNode(" в разделе Взять реферальную ссылку ")
            ]),
            _: 1
          })
        ], 64)),
    _createVNode(_component_a_button, {
      onClick: _withModifiers(_ctx.handleChallengeClick, ["stop"])
    }, {
      default: _withCtx(() => [
        _createTextVNode(" Бросить вызов ")
      ]),
      _: 1
    }, 8, ["onClick"])
  ]))
}