import axios, { AxiosInstance, AxiosRequestHeaders } from 'axios';
import ServiceContainerInterface
  from '@/app/Services/ServiceContainer/Contract/ServiceContainerInterface';
import AxiosClientServiceInterface from './Contract/AxiosClientServiceInterface';

class AxiosClientService implements AxiosClientServiceInterface {
  private _instance?: AxiosInstance;

  private serviceContainer: ServiceContainerInterface;

  constructor(
    serviceContainer: ServiceContainerInterface,
  ) {
    this.serviceContainer = serviceContainer;
  }

  get instance(): AxiosInstance {
    if (!this._instance) {
      this._instance = axios.create({
        baseURL: `${this.getBackendHost()}/api`,
        headers: {
          Accept: 'application/json',
        },
      });
    }

    return this._instance;
  }

  addRequestInterceptors = () => {
    this.instance.interceptors.request.use(
      async (config) => {
        config.headers = {
          Accept: 'application/json',
          ...config.headers,
        } as AxiosRequestHeaders;

        const notSignRouteList = [
          '/token/refresh',
          '/telegram-bot/info',
          '/auth/telegram/code',
        ];

        const isRouteForSign = !notSignRouteList.find(
          (route) => config.url?.includes(route),
        );

        if (isRouteForSign) {
          config = this.serviceContainer.authorizationService.signRequest(config);
        }

        return config;
      },
      async (error) => Promise.reject(error),
    );
  };

  addResponseInterceptors = () => {
    this.instance.interceptors.response.use(
      async (response) => response,
      async (error) => {
        const originalRequest = error.config;

        if (error.response) {
          const { status } = error.response;

          if (originalRequest.url === '/token/refresh') {
            return Promise.reject(error);
          }

          if (status === 401) {
            try {
              const success = await this.serviceContainer.authorizationService
                .refreshTokenPair();

              if (success) {
                const request = this.serviceContainer
                  .authorizationService
                  .signRequest(originalRequest);

                return this.instance(request);
              }
              await this.serviceContainer
                .authorizationService
                .logout();

              return Promise.reject();
            } catch (e) {
              await this.serviceContainer
                .authorizationService
                .logout();

              return Promise.reject(e);
            }
          }
        }

        return Promise.reject(error);
      },
    );
  };

  private getBackendHost = (): string => window.location.origin;
}

export default AxiosClientService;
