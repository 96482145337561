import JsonToolInterface from './Contract/JsonToolInterface';
import UtilsServiceInterface from './Contract/UtilsServiceInterface';
import JsonToolException from './Exception/JsonToolException';

class JsonTool implements JsonToolInterface {
  private utilsService: UtilsServiceInterface;

  constructor(utilsService: UtilsServiceInterface) {
    this.utilsService = utilsService;
  }

  from = <T>(json: string): T => {
    if (!this.utilsService.typeCheck.isString(json)) {
      throw new JsonToolException('Wrong JSON format.');
    }

    try {
      const result = JSON.parse(json);

      if (
        !this.utilsService.typeCheck.isArray(result)
        && !this.utilsService.typeCheck.isObject(result)
      ) {
        throw new JsonToolException('Wrong JSON format.');
      }

      return result as unknown as T;
    } catch (e) {
      throw new JsonToolException('Wrong JSON format.');
    }
  };

  to = <T>(data: T): string => {
    const isArray = this.utilsService.typeCheck.isArray(data);
    const isObject = this.utilsService.typeCheck.isObject(data);

    if (!isArray && !isObject) {
      throw new JsonToolException('Only objects & arrays allowed.');
    }

    if (isArray) {
      return JSON.stringify(this.castUndefinedToNullInArrayRecursive(data));
    }

    return JSON.stringify(this.castUndefinedToNullInObjectRecursive(
      data as { [key in string]: unknown },
    ));
  };

  private castUndefinedToNullInArrayRecursive = (array: unknown[]) => array
    .reduce((result: unknown[], value) => {
      if (this.utilsService.typeCheck.isUndefined(value)) {
        result.push(null);
      } else if (this.utilsService.typeCheck.isArray(value)) {
        result.push(this.castUndefinedToNullInArrayRecursive(value));
      } else if (this.utilsService.typeCheck.isObject(value)) {
        result.push(this.castUndefinedToNullInObjectRecursive(
          value as { [key in string]: unknown },
        ));
      } else {
        result.push(value);
      }

      return result;
    }, []);

  private castUndefinedToNullInObjectRecursive = (
    obj: { [key in string]: unknown },
  ) => Object.keys(obj)
    .reduce((result: { [key in string]: unknown }, key) => {
      if (this.utilsService.typeCheck.isUndefined(obj[key])) {
        result[key] = null;
      } else if (this.utilsService.typeCheck.isArray(obj[key])) {
        result[key] = this.castUndefinedToNullInArrayRecursive(obj[key] as []);
      } else if (this.utilsService.typeCheck.isObject(obj[key])) {
        result[key] = this.castUndefinedToNullInObjectRecursive(
          obj[key] as { [key in string]: unknown },
        );
      } else {
        result[key] = obj[key];
      }

      return result;
    }, {} as { [key in string]: unknown });
}

export default JsonTool;
