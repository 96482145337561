import ArrayToolInterface from './Contract/ArrayToolInterface';
import UtilsServiceInterface from './Contract/UtilsServiceInterface';
import TypeCheckToolInterface from './Contract/TypeCheckToolInterface';
import ComparatorToolInterface from './Contract/ComparatorToolInterface';
import DataToolInterface from './Contract/DataToolInterface';
import StringToolInterface from './Contract/StringToolInterface';
import ClipboardToolInterface from './Contract/ClipboardToolInterface';
import JsonToolInterface from './Contract/JsonToolInterface';
import ObjectToolInterface from './Contract/ObjectToolInterface';
import TypeCheckTool from './TypeCheckTool';
import ArrayTool from './ArrayTool';
import ComparatorTool from './ComparatorTool';
import DataTool from './DataTool';
import StringTool from './StringTool';
import ClipboardTool from './ClipboardTool';
import JsonTool from './JsonTool';
import ObjectTool from './ObjectTool';
import FileToolInterface from './Contract/FileToolInterface';
import FileTool from './FileTool';

class UtilsService implements UtilsServiceInterface {
  get array(): ArrayToolInterface {
    return new ArrayTool(this);
  }

  get typeCheck(): TypeCheckToolInterface {
    return new TypeCheckTool();
  }

  get comparator(): ComparatorToolInterface {
    return new ComparatorTool(this);
  }

  get data(): DataToolInterface {
    return new DataTool(this);
  }

  get string(): StringToolInterface {
    return new StringTool();
  }

  get clipboard(): ClipboardToolInterface {
    return new ClipboardTool();
  }

  get json(): JsonToolInterface {
    return new JsonTool(this);
  }

  get object(): ObjectToolInterface {
    return new ObjectTool(this);
  }

  get file(): FileToolInterface {
    return new FileTool();
  }
}

export default UtilsService;
