import { Module } from 'vuex';
import TournamentTemplateModel from '@/app/Store/Model/TournamentTemplateModel';
import TournamentTemplateRPCModel from '@/shared/Api/Model/RPC/Tournament/TournamentTemplateModel';

export const tournamentTemplate: Module<TournamentTemplateModel, unknown> = {
  state: {
    tournamentTemplate: null,
  },

  getters: {
    getTournamentTemplate(state) {
      return state.tournamentTemplate;
    },
  },

  mutations: {
    setTournamentTemplate(state, payload) {
      state.tournamentTemplate = payload;
    },
  },

  actions: {
    addTournamentTemplate({ commit }, tournamentTemplate: TournamentTemplateRPCModel) {
      commit('setTournamentTemplate', tournamentTemplate);
    },

    deleteTournamentTemplate({ commit }) {
      commit('setTournamentTemplate', null);
    },
  },
};
