import InvitationChallengeApiInterface
  from '@/shared/Api/Contract/Api/InvitationChallengeApiInterface';
import SendInvitationChallengeRequestModel
  from '@/shared/Api/Model/InvitaitonChallengeApi/SendInvitationChallengeRequestModel';
import AxiosClientServiceInterface
  from '@/app/Services/AxiosClientService/Contract/AxiosClientServiceInterface';

class InvitationChallengeApi implements InvitationChallengeApiInterface {
  private clientService: AxiosClientServiceInterface;

  constructor(
    clientService: AxiosClientServiceInterface,
  ) {
    this.clientService = clientService;
  }

  send(data: SendInvitationChallengeRequestModel): Promise<unknown> {
    return this.clientService.instance.post(
      '/invitation-to-challenge/create',
      data,
    );
  }
}

export default InvitationChallengeApi;
