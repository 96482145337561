class WordDeclensionHelper {
  static choice(number: number, variants: Array<string>): string {
    if (number === 0) {
      return variants[0] ?? '';
    }

    const teen = number % 100 > 10 && number % 100 < 20;
    const endsWithOne = number % 10 === 1;

    if (variants.length < 4) {
      const key = (!teen && endsWithOne) ? 1 : 2;

      return variants[key];
    }

    if (!teen && endsWithOne) {
      return variants[1] ?? '';
    }

    if (!teen && number % 10 >= 2 && number % 10 <= 4) {
      return variants[2] ?? '';
    }

    const key = variants.length < 4 ? 2 : 3;

    return variants[key] ?? '';
  }

  static getDayDeclension(number: number): string {
    return this.choice(
      number,
      ['дней', 'день', 'дня', 'дней'],
    );
  }

  static getTournamentDeclension(number: number): string {
    return this.choice(
      number,
      ['турниров', 'турнир', 'турнира', 'турниров'],
    );
  }

  static getChallengeDeclension(number: number): string {
    return this.choice(
      number,
      ['целей-челленджей', 'цель-челлендж', 'цели-челленджа', 'целей-челленджей'],
    );
  }

  static getFriendDeclension(number: number): string {
    return this.choice(
      number,
      ['друзей', 'друга', 'друзей', 'друзей'],
    );
  }
}

export default WordDeclensionHelper;
