import ListActualMethodInterface
  from '@/shared/Api/Contract/Api/RPC/Tournament/ListActualMethodInterface';
import AxiosClientServiceInterface
  from '@/app/Services/AxiosClientService/Contract/AxiosClientServiceInterface';
import TournamentTemplateModel from '@/shared/Api/Model/RPC/Tournament/TournamentTemplateModel';

class ListActualMethod implements ListActualMethodInterface {
  private readonly client: AxiosClientServiceInterface;

  constructor(
    client: AxiosClientServiceInterface,
  ) {
    this.client = client;
  }

  async call(): Promise<TournamentTemplateModel[]> {
    return this.client.instance.post<TournamentTemplateModel[]>('/v2/tournament/listActual')
      .then((response) => response.data);
  }
}

export default ListActualMethod;
