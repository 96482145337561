import ExternalLinkServiceInterface
  from '@/app/Services/ExternalLinkService/Contract/ExternalLinkServiceInterface';
import ExternalLinkEnum from '@/entities/ExternalLinkEnum';

class ExternalLinkService implements ExternalLinkServiceInterface {
  async getPremium(): Promise<string> {
    return Promise.resolve(ExternalLinkEnum.PREMIUM_SECTION);
  }

  async getSalebotCreateChallenge(): Promise<string> {
    return Promise.resolve(ExternalLinkEnum.SALEBOT_CREATE_CHALLENGE);
  }

  async getTelegramImportantSection(): Promise<string> {
    return Promise.resolve(ExternalLinkEnum.TELEGRAM_IMPORTANT_SECTION);
  }

  async getTelegramMainChat(): Promise<string> {
    return Promise.resolve(ExternalLinkEnum.TELEGRAM_MAIN_CHAT);
  }

  getTreasureIsland(): Promise<string> {
    return Promise.resolve(ExternalLinkEnum.TREASURE_ISLAND);
  }
}

export default ExternalLinkService;
