import { Module } from 'vuex';
import AuthStateModel from '@/app/Store/Model/AuthStateModel';

export const authStore: Module<AuthStateModel, unknown> = {
  state: {
    token: undefined,
    refreshToken: undefined,
  },
  getters: {
    getToken(state) {
      return state.token;
    },
    getRefreshToken(state) {
      return state.refreshToken;
    },
  },
  mutations: {
    setToken(state, payload) {
      state.token = payload;
    },
    setRefreshToken(state, payload) {
      state.refreshToken = payload;
    },
  },
  actions: {
    updateToken({ commit }, value) {
      commit('setToken', value);
    },
    updateRefreshToken({ commit }, value) {
      commit('setRefreshToken', value);
    },
  },
};
