import { Module } from 'vuex';
import UserStateModel from '@/app/Store/Model/UserStateModel';
import ServiceContainerInterface
  from '@/app/Services/ServiceContainer/Contract/ServiceContainerInterface';

export function getUserModule(serviceContainer: ServiceContainerInterface): Module<UserStateModel, unknown> {
  return {
    state: {
      user: undefined,
    },
    getters: {
      getUser(state) {
        return state.user;
      },
    },
    mutations: {
      setUser(state, payload) {
        state.user = payload;
      },
    },
    actions: {
      async updateUser({ commit }) {
        const user = await serviceContainer.apiService.userInfoApi
          .getInfo()
          .catch(() => null);

        commit('setUser', user);
      },
    },
  };
}
